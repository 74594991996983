.value {
    width: 295px;
    height: 24px;
    font-size: 16px;
    line-height: 24px;
    color: #202124;
    font-weight: 400;
    border: none;
    border-bottom: 2px solid white;
    outline: none;
}

