.app {
  display: flex;
  width: 100%;
  position: relative;
  flex: 0 0 auto;
}

.left {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  flex: 0 0 auto;
  padding: 1rem;
  box-sizing: border-box;
}

.right {
  position: relative;
  display: none;
}

.pic {
  background-image: url("./pic.svg");
  background-repeat: no-repeat;
  width: 752px;
  height: 855px;
  background-position: 0 -65px;
  visibility: hidden;
}

.logo {
  background-image: url("./logo.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 185px;
  height: 24px;
}

.left > .logo {
  margin: 1rem auto;
}

.right > .logo {
  position: absolute;
  top: 100px;
  left: 180px;
}

@media screen and (min-width: 400px) {
  .left {
    width: 360px;
    margin: 1rem auto;
  }
}

@media screen and (min-width: 968px) {
  .app {
    width: 968px;
    margin: 0 auto;
  }

  .right {
    display: block;
  }

  .left {
    width: 360px;
    margin: 80px 0 20px 0;
  }

  .left > .logo {
    display: none;
  }
}

.header {
  font-size: 36px;
  font-style: normal;
  font-weight: 800;
  line-height: 32px;
  letter-spacing: 0;
  text-align: left;
  color: #202124;
}

.description {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0;
  text-align: left;
}


