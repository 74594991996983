.wrapper {
    position: relative;
    user-select: none;
}

.input {
    height: 24px;
    font-size: 16px;
    line-height: 24px;
    color: #202124;
    font-weight: 400;
    border: none;
    border-bottom: 2px solid white;
    outline: none;
    padding: 0;
    user-select: none;
}

.options {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 1.6rem;
    display: flex;
    flex-direction: column;
    border: 2px solid #CDD4D9;
    border-radius: 2px;
    background: white;
    z-index: 1000;
    overflow-x: hidden;
    overflow-y: auto;
}

.option {
    cursor: pointer;
    padding: 0 0.7rem;
    white-space: nowrap;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
    color: #82939F;
}

.option:hover {
    background-color: #eeeeee;
}

.input::selection {
    background-color: white;
}

.input.active,.input:focus {
    border-bottom: 2px solid #06273F;
}

.input.active,.option.active {
    color: #06273F;
}
