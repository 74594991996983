.textarea {
    width: 100%;
    height: 24px;
    font-family: "Google Sans",Roboto,Arial,sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: #202124;
    font-weight: 400;
    border: none;
    border-bottom: 2px solid white;
    outline: none;
    overflow-y: hidden;
    overflow-x: hidden;
    resize: none;
    padding: 0;
}

.textarea:focus {
    border-bottom: 2px solid #06273F;
}

.textarea.error {
    border-bottom-color: #d93025;
}
