.title {
    color: #82939F;
    display: flex;
    align-items: center;
    gap: 0.2rem;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0;
    text-align: left;
    padding: 8px 0;
}

.optional {
    color: #FF9400;
    white-space: pre;
}
