@font-face {
  font-family: 'InterRegular';
  src: local('InterRegular'), url(./InterRegular.woff) format('woff');
}

body {
  margin: 0;
  font-family: InterRegular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ffffff;
}
