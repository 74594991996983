.submit {
    background-color: #FF9400;
    border: none;
    outline: none;
    border-radius: 12px;
    padding: 0 24px;
    width: 180px;
    cursor: pointer;
    display: flex;
    height: 48px;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0;
    text-align: left;
    color: #06273F;
}

.submit:hover,.submit:focus {
    opacity: 0.9;
}

.submit:active {
    opacity: 0.5;
}

.submit:disabled {
    opacity: 0.3;
}

@keyframes spinner {
    to { transform: rotate(360deg); }
}

.spinner {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    /*vertical-align: text-bottom;*/
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner .75s linear infinite;
}
