.input {
    width: 100%;
    height: 24px;
    font-size: 16px;
    line-height: 24px;
    color: #202124;
    font-weight: 400;
    border: none;
    border-bottom: 2px solid white;
    outline: none;
    padding: 0;
}

.input:focus {
    border-bottom: 2px solid #06273F;
}

input:-webkit-autofill::first-line,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    font-size: 16px !important;
    line-height: 24px !important;
    font-weight: 400 !important;
    -webkit-box-shadow: 0 0 0 1000px white inset;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #202124 !important;
}

